.modal-subscribe {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(38, 50, 56, 0.8);
    z-index: 99999;
    pointer-events: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    opacity: 0;
    visibility: hidden;
    &:target {
        opacity: 1;
        visibility: visible;
    }
    .modal-content {
        background: #fff;
        max-width: 500px;
        width: 100%;
        padding: 20px;
        position: relative;
        pointer-events: all;
        .modal-close {
            font-size: 30px;
            position: absolute;
            right: 20px;
            top: 25px;
            color: #CFD8DC;
            z-index: 1;
        }
        form {
            display: flex;
            flex-direction: column;
            input[type="text"], textarea {
                resize: none;
                border: 0;
                border-bottom: 1px solid #BDBDBD;
                margin-bottom: 10px;
                padding: 10px;
                @mixin font;
                &.has-error {
                    border-color: #F44336;
                }
                &.has-success {
                    border-color: #4CAF50;
                }
            }
            .btn {
                width: 50%;
                margin: 0 auto;
                min-width: 100px;
            }
        }
    }
}
