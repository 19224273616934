.l-logos {
    h2 {
        font-family: 'Ahellya';
        font-size: 30px;
        text-align: center;
        font-style: italic;
        margin: 20px 0 35px;
        color: #263238;
    }
    .swiper-container {
        width: 100%;
        height: 100%;
        padding-bottom: 50px;
        overflow: visible;
        @media (--media-md) {
            overflow: hidden;
        }
    }
    .swiper-slide {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #fff;
        border-radius: 5px;
        .logo-image {
            width: 180px;
            height: 120px;
            background-color: #fff;
            background: no-repeat 50% 50%;
            background-size: cover;
            box-shadow: 0 30px 60px -20px rgba(0, 0, 0, 0.25);
        }
    }
}