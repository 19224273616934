body {
    font-size: 16px;
    @mixin font;
    overflow-x: hidden;
}

.container {
    position: relative;
    max-width: 1360px;
    margin: auto;
    padding: $block-indent-size;
}

.material-icons {
    position: relative;
    top: 3px;
    font-size: 100%;
}

.section-divider {
    height: 50px;
    width: 100%;
    fill: #fff;
    position: relative;
    top: 4px;
}