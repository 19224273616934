.l-footer {
    background: url('../img/footer-image.jpg') no-repeat 50% 50%;
    height: 350px;
    margin-top: -200px;
    padding-top: 130px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media (--media-md) {
        justify-content: inherit;
        margin-top: -160px;
        padding-top: 110px;
    }
    .welcome-free {
        font-size: 14px;
        margin-top: 7px;
    }
    .footer-social {
        display: flex;
        align-items: center;
        @media (--media-md) {
            flex-direction: row;
            margin-top: 28px;
        }
        svg {
            fill: #455a64;
            height: 25px;
            transition: fill .5s;
            &:hover {
                fill: #447e9a;
            }
        }
        li {
            margin-right: $block-indent-size;
            &:last-child {
                margin-right: 0;
            }
            &.vkontakte svg {
                height: 35px;
                position: relative;
            }
            a {
                display: block;
                text-align: center;
            }
        }
    }
    ul {
        display: flex;
        margin: 10px 0;
        align-items: flex-end;
        @media (--media-md) {
            flex-direction: column;
        }
        li {
            margin: 0 10px;
            @media (--media-md) {
                margin: 10px 0;
            }
        }
    }
}