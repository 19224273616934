.l-welcome {
	background: #ffffff url('../img/header-img.jpg') no-repeat 100% 50% fixed;
	background-size: cover;
	height: 101%;
	opacity: 0;
	transform: scale(1.1);
	transition: transform .8s, opacity .8s;
	@media (--media-md) {
		background-attachment: initial;
		height: auto;
		background-position: 50% 50% !important;
	}
	&.is-animated {
		transform: scale(1);
		opacity: 1;
	}
	&:after {
		content: '';
		width: 100%;
		height: 13px;
		background: url('../img/header-pattern.svg') repeat-x;
		position: absolute;
		bottom: 0;
		background-size: 20px 20px;
	}
	.container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		height: 100%;
	}
	h2 {
		color: #ffffff;
		text-shadow: 1px 1px 6px rgba(0, 0, 0, 0.3);
		font-size: 37px;
		font-weight: 600;
		margin: 0;
		font-family: merriweather;
		text-rendering: optimizeLegibility;
		@media (--media-md) {
			font-size: 31px;
			margin-top: 5px;
		}
	}
	&.is-animated .welcome-container {
		transform: translate(0, 0);
		opacity: 1;
	}
	.welcome {
		&-logo {
			max-width: 340px;
			width: 100%;
			filter: brightness(8);
			border-bottom: 1px solid #fff;
			padding-bottom: 6px;
		}
		&-date {
			font-size: 23px;
			margin-bottom: 20px;
			color: #ffffff;
			text-shadow: 1px 1px 6px rgba(0, 0, 0, 0.3);
			font-family: merriweather;
			text-rendering: optimizeLegibility;
			.header-social {
				display: flex;
				align-items: center;
				justify-content: center;
				svg {
					fill: #fff;
					height: 40px;
					transition: filter .5s, fill .5s;
					&:hover {
						filter: drop-shadow(4px 4px 4px rgba(99, 119, 152, 0.3));
						fill: #B3E5FC;
					}
				}
				li {
					margin-right: $block-indent-size;
					&:last-child {
						margin-right: 0;
					}
					&.vkontakte svg {
						height: 65px;
						position: relative;
					}
					a {
						display: block;
						text-align: center;
					}
				}
			}
			.date-holder {
				font-size: 76px;
				border-top: 1px solid #fff;
				border-bottom: 1px solid #fff;
				margin: 14px 0;
				.date {
					width: 170px;
					float: left;
					display: flex;
					font-family: 'Roboto Condensed', sans-serif;
					font-size: 90px;
					@media (--media-md) {
						width: auto;
						font-size: 70px;
						margin-right: 7px;
					}
					.material-icons {
						top: 27px;
						margin-right: 18px;
						font-size: 60%;
						@media (--media-md) {
							top: 20px;
						}
					}
				}
				.day {
					text-align: left;
					font-size: 43px;
					margin: 20px 0 0 9px;
					width: 163px;
					float: left;
					line-height: 35px;
					@media (--media-md) {
						font-size: 37px;
						margin: 12px 0 0 9px;
						width: auto;
						display: flex;
						flex-direction: column;
					}
					span {
						font-size: 25px;
						padding-top: 0;
						@media (--media-md) {
							font-size: 22px;
						}
					}
				}
				.time {
					font-family: 'Roboto Condensed', sans-serif;
					font-size: 80px;
					clear: both;
					border-top: 1px solid #fff;
					@media (--media-md) {
						font-size: 68px;
					}
					i {
						top: 8px;
					}
				}
			}
		}
		&-free {
			display: block;
			margin-top: 10px;
			font-size: 14px;
			color: #ffffff;
			opacity: .6;
		}
		&-container {
			max-width: 342px;
			width: 100%;
			margin: 0 10%;
			text-align: center;
			position: relative;
			transform: translate(0, -50px);
			opacity: 0;
			transition: opacity 1s, transform 1s;
			&:after {
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				background-image: linear-gradient(126deg, #fffbef 0%, rgba(255, 251, 239, 0.5) 100%);
			}
		}
	}
	.scroll {
		position: absolute;
		left: 50%;
		width: 20px;
		height: 40px;
		margin-left: -10px;
		bottom: 20px;
		border-radius: 25px;
		display: block;
		z-index: 1;
		cursor: pointer;
		opacity: 1;
		transition: transform 1s, opacity .3s;
		transform: scale(1);
		border: 1px solid rgba(255, 255, 255, 0.4);
		@media (--media-md) {
			display: none;
		}
		&.is-hidden {
			opacity: 0;
		}
		&:before {
			position: absolute;
			left: 50%;
			content: '';
			width: 4px;
			height: 4px;
			background: #eceff1;
			margin-left: -2px;
			top: 5px;
			border-radius: 4px;
			animation: scroll 1.5s infinite;
		}
	}
}

@keyframes scroll {
	0% {
		opacity: 1
	}
	100% {
		opacity: 0;
		transform: translateY(20px);
	}
}