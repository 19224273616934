* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: 0 none !important;
}

*::-moz-focus-inner {
  border: 0;
}

::-moz-selection {
  background: #81D4FA;
  color: #fff;
  text-shadow: none;
}

::selection {
  background: #81D4FA;
  color: #fff;
  text-shadow: none;
}

::-webkit-scrollbar {
    width: 5px;
        height: 5px;
        background: #b2dfdb;
}

::-webkit-scrollbar-track {
    border-left: 1px solid #b2dfdb;
        background: #FFFFFF;
}

::-webkit-scrollbar-thumb {
background: #b2dfdb;
}

::-webkit-scrollbar-thumb:hover {
  -webkit-box-shadow: inset 1px 1px 10px rgba(0, 0, 0, 0.3);
}

/*::-webkit-scrollbar-thumb:active {
  background: #888;
  -webkit-box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.3);
}*/

body, html {
  width: 100%;
  height: 100%;
}

body {
  min-width: 320px;
  color: $color-primary-text;
  line-height: 1.42857;
}

a {
  display: inline-block;
  color: $color-primary-text;
  text-decoration: none;
  transition: all .3s;

}

.clearfix {
  &:after {
    display: block;
    visibility: hidden;
    clear: both;
    height: 0;
    content: " "; /* Older browser do not support empty content */
  }
}

p {
  margin-bottom: $block-indent-size;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  position: relative;
  z-index: 1;
  display: block;
  margin: 0 0 $block-indent-size 0;
}

h1 {
  font-size: 2.2em;
}

h2 {
  font-size: 2em;
}

h3 {
  font-size: 1.8em;
}

h4 {
  font-size: 1.6em;
}

h5 {
  font-size: 1.4em;
}

h6 {
  font-size: 1.2em;
}

img {
  max-width: 100%;
  height: auto;
  pointer-events: none;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.loading {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  overflow: hidden;
  margin: auto;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);

  .loader {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 63px;
    height: 13px;
    background: url("../img/loader.gif") center no-repeat;
  }
}
