.btn {
    display: inline-block;
    padding: 15px 25px;
    border: 0;
    border-radius: 30px 12px;
    text-decoration: none;
    font: 400 16px 'merriweather', sans-serif;
    line-height: 1;
    cursor: pointer;
    letter-spacing: .5px;
    &-bordered {
        border-width: 1px;
        border-style: solid;
    }
    &-important {
        background: #FF5722;
        box-shadow: 0 15px 25px -5px #FFCCBC;
        color: #FBE9E7;
        &:hover {
            background: color(#FF5722 lightness(70%));
        }
    }
    &-blue {
        background: #00BCD4;
        box-shadow: 0 15px 25px -5px rgba(0, 96, 100, 0.3);
        color: #E0F2F1;
                &:hover {
            background: color(#00BCD4 lightness(45%));
        }
    }
    &-green {
        background: #26A69A;
        box-shadow: 0 15px 25px -5px rgba(28, 212, 188, 0.48);
        color: #E0F2F1;
        &:hover {
            background: color(#26A69A lightness(60%));
        }
    }
}