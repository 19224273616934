.l-program {
    background-color: #F5F5F5;
    @media (--media-md) {
        overflow: hidden;
    }
    h2 {
        font-family: 'Ahellya';
        font-size: 30px;
        text-align: center;
        font-style: italic;
        margin: 20px 0 35px;
        color: #263238;
    }
    &.is-animated ul li {
        opacity: 1;
        transform: translate(0, 0);
    }
    ul {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        height: 100%;
        li {
            width: 33.33%;
            padding: 0 40px 40px 40px;
            opacity: 0;
            transform: translate(0, -30px);
            transition: transform .8s, opacity .5s;
            @media (--media-lg) {
                width: 50%;
            }            
            @media (--media-md) {
                width: 100%;
            }
            @media (--media-md) {
                width: 100%;
                height: 100%;
                display: block;
                margin-bottom: 40px;
            }
            &:nth-child(2) {
                transition-delay: .2s;
            }
            &:nth-child(3) {
                transition-delay: .3s;
            }
            &:nth-child(4) {
                transition-delay: .4s;
            }
            &:nth-child(5) {
                transition-delay: .5s;
            }
            &:nth-child(6) {
                transition-delay: .6s;
            }
            &:nth-child(7) {
                transition-delay: .7s;
            }
            &:nth-child(8) {
                transition-delay: .8s;
            }
            &:last-child {
                margin: 0;
                transition-delay: .9s;
            }
            p {
                margin: 0;
                letter-spacing: 1px;
                font-size: 15px;
                font-family: 'Ahellya';
                font-style: italic;
                text-align: center;
                margin-top: 10px;
                color: #455A64;
            }
        }
    }
    .program-image {
        width: 100%;
        height: 320px;
        border: 7px solid #e8f1f0;
        background: no-repeat 50% 50%;
        background-size: cover;
        box-shadow: 0 20px 50px -25px #cacaca;
        &.p-image-0 {
            background-image: url('../img/image-0.jpg');
        }
        &.p-image-1 {
            background-image: url('../img/image-1.jpg');
        }
        &.p-image-2 {
            background-image: url('../img/image-2.jpg');
        }
        &.p-image-3 {
            background-image: url('../img/image-3.jpg');
        }
        &.p-image-4 {
            background-image: url('../img/image-4.jpg');
        }
        &.p-image-5 {
            background-image: url('../img/image-5.jpg');
        }
        &.p-image-6 {
            background-image: url('../img/image-6.jpg');
        }
        &.p-image-7 {
            background-image: url('../img/image-7.jpg');
        }
        &.p-image-8 {
            background-image: url('../img/image-8.jpg');
        }
        &.p-image-9 {
            background-color: #fff;
            background-image: url('../img/ministerium.png');
            background-size: 90%;
        }
    }
    .program-btn {
        display: block;
        margin: 7px 0 0 0;
        width: 270px;
        .btn {
            font: 400 19px 'Raleway', sans-serif;
        }
        .welcome-free {
            display: block;
            margin-top: 4px;
            color: #6f8885;
            text-align: center;
            font: 400 15px 'Raleway', sans-serif;
        }
    }
}

.l-registration {
    background: url('../img/ministerium.jpg') no-repeat 50% 50% fixed;
    padding: 140px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-size: cover;
    position: relative;
    @media (--media-md) {
        background-attachment: initial;
    }
    h2 {
        color: #ffffff;
        text-shadow: 1px 1px 6px rgba(0, 0, 0, 0.3);
        font-size: 37px;
        font-weight: 600;
        margin: 0;
        font-family: merriweather;
        @media (--media-md) {
            font-size: 27px;
        }
    }
    p {
        color: #ffffff;
        text-shadow: 1px 1px 6px rgba(0, 0, 0, 0.3);
        font-size: 20px;
        font-weight: 600;
        font-family: merriweather;
        @media (--media-md) {
            font-size: 15px;
        }
    }
    &:after, &:before {
        content: '';
        width: 100%;
        height: 13px;
        background: url('../img/header-pattern.svg') repeat-x;
        position: absolute;
        bottom: 0;
        background-size: 20px 20px;
    }
    &:before {
        top: 0;
        background-image: url('../img/header-grey-pattern.svg');
        transform: rotate(-180deg);
    }
    .btn {
        margin-bottom: 14px;
        color: #fff;
    }
    .welcome-free {
        display: block;
        font-size: 14px;
        color: #ffffff;
        opacity: .6;
    }
}