.no-trans {
	transition: none !important;
}

.no-border {
	border: 0 !important;

	&:hover {
		 border: 0 !important;
	 }
}

.no-padding {
	padding: 0 !important;
}

.no-margin {
	margin: 0 !important;
}

.no-float {
	float: none !important;
}

.overflow-hidden {
	overflow: hidden;
}


.is-sm-hide {
	@media (--media-sm) {
		display: none;
	}
}

.is-md-hide {
	@media (--media-md) {
		display: none;
	}
}

.is-lg-hide {
	@media (--media-lg) {
		display: none;
	}
}

.is-xl-hide {
	@media (--media-xl) {
		display: none;
	}
}

.no-scroll {
  overflow: hidden;
}