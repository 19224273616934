.l-header {
    .l-welcome {
        overflow: hidden;
        height: 400px;
        color: #fff;
        background-position: 50% 50%;
        background-size: cover;
        @mixin font;
        .swiper-container {
            height: 100%;
            .swiper-image {
                background-position: 50% 50%;
                background-size: cover;
                display: block;
                width: 100%;
                height: 100%;
            }
            .swiper-content {
                position: absolute;
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 100%;
                height: 100%;
            }
        }
    }
    .l-navigation {
        position: relative;
        z-index: 2;
        background: #FAFAFA;
        box-shadow: 0 0 70px -5px #000;
        .container {
            display: flex;
            align-items: center;
            justify-content: space-around;
        }
        .nav-logo {
            display: block;
            width: 200px;
            height: 80px;
            border: 1px solid red;
        }
        .user-menu {
            display: flex;
            align-items: center;
            justify-content: space-around;
            width: 100%;
            text-align: left;
            li {
                a {
                    @mixin font;
                    position: relative;
                    &:after {
                        content: '';
                        position: absolute;
                        border-bottom: 1px solid #558B2F;
                        width: 0%;
                        left: 0;
                        bottom: -5px;
                        transition: width .5s;
                    }
                    &:hover {
                        &:after {
                            width: 100%;
                        }
                    }
                    &.btn {
                        &:after {
                            display: none;
                        }
                    }
                }
                &:hover ul {
                    opacity: 1;
                    visibility: visible;
                    li {
                        margin-bottom: 1px;
                        opacity: 1;
                    }
                }
                ul {
                    opacity: 0;
                    visibility: hidden;
                    position: absolute;
                    padding-top: 45px;
                    transition: all .5s;
                    li {
                        background: #fff;
                        padding: 10px;
                        margin-bottom: -50px;
                        opacity: 0;
                        transition: margin-bottom .5s, opacity .5s;
                        a:after {
                            display: none;
                        }
                        &:hover {
                            background: #DCEDC8;
                            a {
                                color: #558B2F;
                            }
                        }
                        &:nth-child(1) {
                            transition-delay: .1s;
                            z-index: 1;
                        }
                        &:nth-child(2) {
                            transition-delay: .2s;
                            z-index: 2;
                        }
                        &:nth-child(3) {
                            transition-delay: .3s;
                            z-index: 3;
                        }
                        &:nth-child(4) {
                            transition-delay: .4s;
                            z-index: 4;
                        }
                        &:nth-child(5) {
                            transition-delay: .5s;
                            z-index: 5;
                        }
                        &:nth-child(6) {
                            transition-delay: .6s;
                            z-index: 6;
                        }
                        &:nth-child(7) {
                            transition-delay: .7s;
                            z-index: 7;
                        }
                    }
                }
                &.user-menu-tel, &.user-menu-adress {
                    display: flex;
                    flex-direction: column;
                    @mixin font;
                    a {
                        margin: 2px 0;
                        &:after {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}