/* ---------------------- */
/* == Base settings
/* ---------------------- */
$block-indent-size: 15px;


/* ---------------------- */
/* == Colors list
/* ---------------------- */
$color-primary-text: #455a64;


/* ---------------------- */
/* == Mobile First Method
/* ---------------------- */
@custom-media --media-xs (max-width: 320px);
@custom-media --media-sm (max-width: 544px);
@custom-media --media-md (max-width: 768px);
@custom-media --media-lg (max-width: 992px);
@custom-media --media-xl (max-width: 1200px);
@custom-media --media-xxl (max-width: 1400px);
@custom-media --media-xxxl (max-width: 1600px);
