.l-description {
    min-height: 70%;
    display: flex;
    @media (--media-md) {
        max-width: 100%;
        overflow-x: hidden;
        margin: 10px 0;
    }
    .container {
        flex-direction: column;
        display: flex;
        padding: 0;
        @media (--media-md) {
            flex-direction: column;
        }
    }
    &.is-animated .description-content, &.is-animated .knowledge-content {
        transform: translate(0, 0);
        opacity: 1;
    }
    .description-content {
        width: 100%;
        padding: 20px;
        position: relative;
        transform: translate(-150px, 0);
        opacity: 0;
        transition: opacity .8s, transform .8s;
        text-align: center;
        color: #263238;
        h3 {
            font-family: 'Ahellya';
            font-size: 30px;
            text-align: center;
            font-style: italic;
            margin-bottom: 40px;
        }
        p {
            font-size: 20px;
            position: relative;
            z-index: 1;
            font-family: 'Ahellya';
            strong {
                font-weight: 700;
            }
        }
    }
    .knowledge-content {
        width: 100%;
        position: relative;
        padding: 20px;
        border-radius: 50px;
        margin-bottom: 30px;
        text-align: center;
        transform: translate(150px, 0);
        opacity: 0;
        transition: opacity .8s, transform .8s;
        ul {
            z-index: 1;
            position: relative;
            text-align: left;
            display: flex;
            flex-direction: row;
            @media (--media-md) {
                flex-direction: column;
                display: block;
                float: left;
                margin: 10px 0;
            }
            @media (--media-sm) {
                display: block;
                margin: 10px 0;
            }
            &:after {
                content: '';
                clear: both;
                display: table;
            }
            li {
                font-size: 15px;
                font-weight: 300;
                margin: 2px 0;
                height: 100%;
                color: #263238;
                width: 100%;
                font-family: 'Ahellya';
                text-align: center;
                font-style: italic;
                letter-spacing: .5px;
                @media (--media-md) {
                    display: block;
                    width: 50%;
                    float: left;
                    margin-bottom: 40px;
                    &:last-child {
                        width: 100%;
                    }
                }
                @media (--media-sm) {
                    display: block;
                    margin-bottom: 40px;
                }
                .material-icons {
                    display: block;
                    font-size: 50px;
                    background: rgb(139, 221, 232);
                    width: 90px;
                    height: 90px;
                    line-height: 80px;
                    border-radius: 50%;
                    margin: 0 auto 25px;
                    border: 4px double rgb(77, 210, 224);
                    box-shadow: 0 10px 50px -15px #01a1b5;
                    text-shadow: 1px 1px 3px #6cbac3;
                    color: #E0F7FA;
                    user-select: none;
                    cursor: default;
                }
            }
        }
        h3 {
            font-family: 'Ahellya';
            font-size: 30px;
            text-align: center;
            font-style: italic;
            margin-bottom: 50px;
            color: #263238;
        }
    }
}